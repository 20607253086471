import React, { Suspense, lazy } from 'react';
import {
  Link,
  Route,
  HashRouter as Router,
  Switch,
} from 'react-router-dom';
//import { IntlProvider } from 'react-intl';
import PropTypes from 'prop-types';

const HubHome = lazy(() => import('./components/hub/home/Home'));
const HubGallery = lazy(() => import('./components/hub/gallery/Gallery'));
const HubBlog = lazy(() => import('./components/hub/blog/Blog'));
const HubAbout = lazy(() => import('./components/hub/about/About'));
const HubContact = lazy(() => import('./components/hub/contact/Contact'));

const AiandroboticsHome = lazy(() => import('./components/aiandrobotics/home/Home'));

const StudioHome = lazy(() => import('./components/studio/home/Home'));

const MarketplaceHome = lazy(() => import('./components/marketplace/home/Home'));
const Payment = lazy(() => import('./components/marketplace/payment/Payment'));
const PaymentCart = lazy(() => import('./components/marketplace/paymentCart/PaymentCart'));
const ProductDetails = lazy(() => import('./components/marketplace/productDetails/ProductDetails'));
const ProductDetailsEModule = lazy(() => import('./components/marketplace/productDetailsEModule/ProductDetailsEModule'));

const EducationHome = lazy(() => import('./components/education/home/Home'));
const Magazine1 = lazy(() => import('./components/education/magazine1/Magazine'));
const Magazine2 = lazy(() => import('./components/education/magazine2/Magazine'));
const Magazine3 = lazy(() => import('./components/education/magazine3/Magazine'));
const Magazine4 = lazy(() => import('./components/education/magazine4/QuadraticActivity'));
const MyEducationCatalog = lazy(() => import('./components/education/myEducationCatalog/MyEducationCatalog'));


//const SearchPage = lazy(() => import('./components/education/searchPage/SearchPage'));
//const MenueIndex = lazy(() => import('./components/education/menueIndex/MenueIndex'));
//const Lesson = lazy(() => import('./components/education/lesson/Lesson'));
//const Quiz = lazy(() => import('./components/education/quiz/Quiz'));
//const BlogPage = lazy(() => import('./components/education/blogPage/BlogPage'));
//const LearningProgress = lazy(() => import('./components/education/learningProgress/LearningProgress'));
//const KnowledgeTree = lazy(() => import('./components/education/catalog/components/knowledgeTree/knowledgeTreeTable/KnowledgeTree'));
//const QuickCatalog = lazy(() => import('./components/education/quickCatalog/QuickCatalog'));
//const KnowledgeTree1 = lazy(() => import('./components/education/knowledgeTree/KnowledgeTree'));
//const ItemCatalog = lazy(() => import('./components/marketplace/itemCatalog/ItemCatalog'));
//const ProductCatalog = lazy(() => import('./components/marketplace/productCatalog/ProductCatalog'));


const NotFound = lazy(() => import('./components/notFound/NotFound'));

import './App.css';

function App() {

  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Route exact path="/" component={HubHome} />
        <Route exact path="/hub/gallery" component={HubGallery} />
        <Route exact path="/hub/blog" component={HubBlog} />
        <Route exact path="/hub/about" component={HubAbout} />
        <Route exact path="/hub/contact" component={HubContact} />

        <Route exact path="/aiandrobotics" component={AiandroboticsHome} />

        <Route exact path="/studio" component={StudioHome} />

        <Route exact path="/marketplace" component={MarketplaceHome} />
        <Route exact path="/marketplace/Payment" component={Payment} />
        <Route exact path="/marketplace/PaymentCart" component={PaymentCart} />
        <Route exact path="/marketplace/ProductDetails" component={ProductDetails} />
        <Route exact path="/marketplace/ProductDetailsEModule" component={ProductDetailsEModule} />
        
        <Route exact path="/education" component={EducationHome} />
        <Route exact path="/education/magazine1" component={Magazine1} />
        <Route exact path="/education/magazine2" component={Magazine2} />
        <Route exact path="/education/magazine3" component={Magazine3} />
        <Route exact path="/education/magazine4" component={Magazine4} />

        <Route exact path="/education/myEducationCatalog" component={MyEducationCatalog} />
          
        <Route path="/notfound" component={NotFound} />
      </Suspense>
    </Router>
  );
}

export default App;